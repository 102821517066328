@import "variables";
@import "main";
@import "navbar";
@import "footer";
@import "bootstrap/bootstrap";

@font-face {
    font-family: 'Carter One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/assets/fonts/CarterOne.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.navbar-brand {
    svg {
        transition: all .2s ease-in-out;
    }
}

.navbar-light .navbar-brand svg {
    fill: $navbar-dark-brand-color;
}

.navbar-dark .navbar-brand svg {
    fill: $navbar-light-brand-color;
}

body {
    background: no-repeat 80% top url("/assets/img/up-gradient.svg") $body-bg;

    @include media-breakpoint-down(xxl) {
        background: no-repeat right -200px top url("/assets/img/up-gradient.svg") $dark;
    }
}

.about {
    margin-top: 7em;
    padding: 0 10px;
    max-width: 800px;

    .content {
        padding: 5% 10%;
        background: rgba(255, 255, 255, 0.01);
        border: 1px solid rgba(255, 255, 255, 0.06);
        box-sizing: border-box;
        backdrop-filter: blur(4px);
        border-radius: 24px;

        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        line-height: 28px;

        p {
            margin-bottom: 38px;
        }

        .avatar {
            display: block;
            margin: 0 auto 20px auto;
        }
    }
}

.welcome {
    padding: 30px 0;
    margin: 60px 0 30px 0;
    
    .avatar {
        margin: 0 auto;
    }
    
    .greeting {
        margin-top: 30px;
        font-family: 'Carter One', cursive;
        font-size: 60px;
        font-weight: 400;
        line-height: 180%;
        color: white;

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
            font-size: 40px;
            line-height: 160%;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 20px 0;
    }
}

.works {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(14, 1fr);
    column-gap: 2em;
    padding: 0 1em;

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
    }
    
    .work {
        height: auto;
        transition: all .2s ease-in-out;
        visibility: hidden;
        
        .work-pic {
            background-color: red;
            border-radius: 1.5em;
            transition: all .2s ease-in-out;
            
            img {
                width: 100%;
                transition: all .2s ease-in-out;
            }
        }
        
        .work-info {
            padding: 1em 0.5em 2em 0.5em;
            
            .work-title {
                font-weight: 600;
                font-size: 30px;
            }
            .work-subtitle {
                font-size: 18px;
                font-weight: 200;
                color: $text-secondary-light;
            }
        }
        
        &:hover {
            .work-pic {
                transform: scale(1.025);
                img {
                    transform: scale(1.05); 
                }
            }
        }
    }
    
    
    // 0
    .work:nth-child(1) {
        
        
        grid-row-start: span 4;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: span 1;
    }
    
    // 1
    .work:nth-child(2) {
        
        
        grid-row-start: span 5;
        grid-row-end: 6;
        grid-column-start: 2;
        grid-column-end: span 1;
    }
    
    // 2
    .work:nth-child(3) {
        
        
        grid-row-start: span 5;
        grid-row-end: 10;
        grid-column-start: 1;
        grid-column-end: span 1;
    }
    
    // 3
    .work:nth-child(4) {
        
        
        grid-row-start: span 5;
        grid-row-end: 11;
        grid-column-start: 2;
        grid-column-end: span 1;
    }
    
    // 4
    .work:nth-child(5) {
        
        
        grid-row-start: span 5;
        grid-row-end: 15;
        grid-column-start: 1;
        grid-column-end: span 1;
    }
    
    // 5
    .work:nth-child(6) {
        
        
        grid-row-start: span 4;
        grid-row-end: 15;
        grid-column-start: 2;
        grid-column-end: span 1;
    }
    
}

.contact {
    margin: 45px 0;
    
    .contact-btn-container {
        .contact-btn {
            background-color: rgba(255, 255, 255, 0.03);
            border-radius: 50px;
            font-size: 18px;
            font-weight: 400;
            padding: 8px;
            height: 56px;
            min-width: 56px;
            margin-right: 25px;
            margin-top: 30px;
            @include media-breakpoint-down(sm) {
                margin-right: 15px;
                margin-top: 15px;
            }

            transition: all .2s ease-in-out;
            
            .btn-title {
                margin-left: 10px;
                color: $gray-600;
            }
        }
        
        
        
        .contact-btn-figma {
            

            
            svg {
                stroke: $gray-600;
            }
            
            
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
                
                
                svg {
                    stroke: none;

                    .a {
                        fill: #F24E1E;
                    }
                    .b {
                        fill: #FF7262;
                    }
                    .c {
                        fill: #A259FF;
                    }
                    .d {
                        fill: #1ABCFE;
                    }
                    .e {
                        fill: #0ACF83;
                    }
                }
                
            }
        }
        
        .contact-btn-linkedin {
            

            
            svg {
                fill: $gray-600;
            }
            
            
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
                
                
                svg {
                    fill: #0077B5;
                }
                .btn-title {
                    color: #0077B5;
                }
                
            }
        }
        
        .contact-btn-twitter {
            

            
            svg {
                fill: $gray-600;
            }
            
            
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
                
                
                svg {
                    fill: #239ECF;
                }
                .btn-title {
                    color: #239ECF;
                }
                
            }
        }
        
        .contact-btn-dribbble {
            

            
            svg {
                fill: $gray-600;
            }
            
            
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
                
                
                svg {
                    fill: #D73E87;
                }
                .btn-title {
                    color: #D73E87;
                }
                
            }
        }
        
        .contact-btn-instagram {
            

            
            svg {
                fill: $gray-600;
            }
            
            
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
                
                
                svg {
                    fill: url(#instagram_0_linear);
                }
                #instagram_0_linear {
                    --color-start: #A81AFF;
                    --color-end: #E47D1E;
                }
                
            }
        }
        
    }
}


#header.navbar.scrolled {
    background: rgba(26, 26, 26, 0.9);
}


.work-section {
}

.work-head {
    height: 500px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;

    img {
        max-height: 83%;
    }

    &.center {
        justify-content: center;

        img {
            max-width: 95%;
        }
    }

    &.left {
        justify-content: left;

        img {
            margin: 0 auto;
            padding: 0 10px;    
        }
    }
}

.work-summary {
    padding: 130px 16px;

    .summary-col {
        flex: 1 1 45%;

        &.left {
            margin: 0 10px 20px 0;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 78px 16px;

        .summary-col {
            flex: 1 1 100%;
        }
    }

    .work-summary-btn {
        border-radius: 8px;
        svg {
            display: inline;
        }
    }
}

.work-section-wrapper {
    &.odd {
        background-color: $dark;
     }
 
     &.even {
         background-color: $darker;
     }
}

.work-content {
    padding: 80px 16px;

    .work-artboard {
        padding: 20px 0;

        @include media-breakpoint-down(sm) {
            padding: 10px 0;
        }
    }

    .work-artboard.default {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        .work-art {
            visibility: hidden;
            flex: 1 0 30%;
            padding: 1.5%;
            img {
                max-width: 100%;
            }
        }
    }

    .work-artboard.double {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        .work-art {
            visibility: hidden;
            flex: 1 0 45%;
            padding: 1.5%;
            img {
                max-width: 100%;
            }
        }
    }

    .work-artboard.board {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        .work-art {
            flex: 1 1 33%;
            padding: 1%;
            img {
                width: 100%;
            }
        }

        .work-art:nth-child(5) {
            flex: 2 2 66%;
        }

        .work-art:nth-child(6) {
            flex: 2 2 66%;
        }

        .work-art:nth-child(9) {
            flex: 2 2 66%;
        }
    }

    .work-artboard.single {
        .work-art {
            margin-left: auto;
            margin-right: auto;
            padding: 32px 0;
            width: 880px;
            max-width: 100%;
            img {
                max-width: 100%;
            }

            @include media-breakpoint-down(sm) {
                padding: 20px 0;
            }
        }
    }

    .work-artboard.withtext {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        .work-art {
            flex: 1 1 45%;
            padding: 1%;
            img {
                border-radius: 8px;
                max-width: 100%;
            }
        }
        .work-text {
            flex: 1 1 45%;
            padding: 2%;
            h4 {
                padding-top: 2%;
                padding-bottom: 2%;
            }
        }

        @include media-breakpoint-down(sm) {
            .work-art {
                flex: 1 1 100%;
            }

            .work-text {
                flex: 1 1 100%;
            }
        }
    }

    .work-artboard.right {
        overflow: hidden;

        .work-art {
            padding: 10px;
            max-width: 50%;
            float: right;
            img {
                width: 100%;
            }
        }
    }

    .work-artboard.slide {
        overflow: hidden;
        .work-art {
            margin-top: 10px;
            position: relative;
            width: 400px;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;

            img {
                position: absolute;
                right: 0;
                width: 100%;
            }
            .video-mask {
                padding: 4.4% 5%;
            }
            video {
                border-radius: 15px; 
                overflow: hidden; 
                
                width: 100%;
            }
        }
    }
}