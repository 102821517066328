@import "bootstrap/functions";
@import "bootstrap/variables";

$dark: #12151C;
$darker: #0C0E12;
$light: rgba(255, 255, 255, 0.85);

$yellow: #FFBE0D;
$orange: #F26905;
$purple: #8969E8;
$green: #00CC83;
$blue: #666BD3;
$cyan: #12D3AD;
$pink: #FF3366;
$primary: $blue;

$text-primary-dark: rgba(black, 0.9);
$text-secondary-dark: rgba(black, 0.65);
$text-disable-dark: rgba(black, 0.25);

$text-primary-light: rgba(white, 0.9);
$text-secondary-light: rgba(white, 0.65);
$text-disable-light: rgba(white, 0.25);

$navbar-light-color: $text-secondary-dark;
$navbar-light-hover-color: $text-primary-dark;
$navbar-light-active-color: $text-primary-dark;

$navbar-dark-color: $text-secondary-light;
$navbar-dark-hover-color: $text-primary-light;
$navbar-dark-active-color: $text-primary-light;

$navbar-light-brand-color: white;
$navbar-dark-brand-color: $text-primary-dark;

$custom-colors: (
  "card-yellow": $yellow,
  "card-orange": $orange,
  "card-purple": $purple,
  "card-green": $green,
  "card-blue": $blue,
  "card-cyan": $cyan,
  "card-pink": $pink,
  "nav-yellow": rgba($yellow, 0.9),
  "nav-orange": rgba($orange, 0.9),
  "nav-purple": rgba($purple, 0.9),
  "nav-green": rgba($green, 0.9),
  "nav-blue": rgba($blue, 0.9),
  "nav-cyan": rgba($cyan, 0.9),
  "nav-pink": rgba($pink, 0.9),
  "nav-dark": rgba($dark, 0.9),
  "dark": $dark,
  "darker": $darker,
  "light": $light,
  "lighter": rgba($light, 0.25),
  "medium": $gray-600,
  "primary": $primary
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$body-bg: $darker;
$body-color: $light;

$link-color: $text-primary-light;
$link-decoration: none;
$link-hover-color: $text-secondary-light;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1080px,
  xxl: 1200px
);

$font-family-sans-serif:
  // Safari for macOS and iOS (San Francisco)
  "SF Pro Display",
  // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // Basic web fallback
  "Helvetica Neue", Arial,
  // Linux
  "Noto Sans",
  "Liberation Sans",
  // Chinese fonts
  "PingFang SC",
  "Source Han Sans CN",
  "Microsoft YaHei",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
