
#header.navbar {
    transition: all .25s ease-in-out;

    &.navbar-blur {
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);    
    }

    nav {
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-brand {
        padding: 2px 0;
        margin: 0 1em;
        
    }

    .navbar-toggler {
        margin: 0 1em;
    }

    .nav-link {
        font-weight: 300;
        font-size: 1.125em;
        padding: 0.4em 1.2em;

        &.active {
            font-weight: 500;
        }
    }
}
